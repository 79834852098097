
export const aldoGallery = {
title: "Aldo Nature Center",
  text:
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic dolore consequatur, mollitia soluta voluptas, accusamus repudiandae, praesentium unde cupiditate corrupti eveniet. Voluptatum optio tenetur quae quibusdam eveniet suscipit totam blanditiis!",
    anchor:"#aldo",
    images:[
  { src: "gallery-aldo-aldo.jpg", alt: "Aldo Nature Center" },
  { src: "gallery-aldo-aldo1.jpg", alt: "Aldo Nature Center" },
  { src: "gallery-aldo-aldo2.jpg", alt: "Aldo Nature Center" },
  { src: "gallery-aldo-aldo3.jpg", alt: "Aldo Nature Center" },
  { src: "gallery-aldo-aldo4.jpg", alt: "Aldo Nature Center" },
  { src: "gallery-aldo-aldo5.jpg", alt: "Aldo Nature Center" },
  { src: "gallery-aldo-aldob4.jpg", alt: "Aldo Nature Center" },
  { src: "gallery-aldo-aldob41.jpg", alt: "Aldo Nature Center" },
  { src: "gallery-aldo-aldob42.jpg", alt: "Aldo Nature Center" },
  { src: "gallery-aldo-aldob43.jpg", alt: "Aldo Nature Center" },
  { src: "gallery-aldo-aldomid.jpg", alt: "Aldo Nature Center" },
  { src: "gallery-aldo-aldomid1.jpg", alt: "Aldo Nature Center" },
  { src: "gallery-aldo-aldomid2.jpg", alt: "Aldo Nature Center" },
  { src: "gallery-aldo-aldomid3.jpg", alt: "Aldo Nature Center" },
  { src: "gallery-aldo-aldomid4.jpg", alt: "Aldo Nature Center" },
]
}

export const uhaulGallery = {
title: "U-Haul",
  text:
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic dolore consequatur, mollitia soluta voluptas, accusamus repudiandae, praesentium unde cupiditate corrupti eveniet. Voluptatum optio tenetur quae quibusdam eveniet suscipit totam blanditiis!",
    anchor:"#uhaul",
    images:[
  { src: "gallery-uhaul-13388903854_3ee3964f8a.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-gy.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-other.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaul.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaul1.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaul10.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaul2.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaul3.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaul4.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaul5.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaul6.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaul7.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaul8.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaul9.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaulafter.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaulafter1.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaulafter2.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaulaftr.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaulaftr1.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaulaftr2.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaulbfr.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaulbfr1.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaulbfr2.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaulbfr3.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaulbfr4.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaulbfr5.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaulbfr6.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaulmid.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaulmid1.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaulmid2.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaulmid3.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaulmid4.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaulmid5.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaulsign.jpg", alt: "U-Haul" },
  { src: "gallery-uhaul-uhaulsign1.jpg", alt: "U-Haul" },
    ]
}

export const zooGallery = {
title: "Zoo",
  text:
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic dolore consequatur, mollitia soluta voluptas, accusamus repudiandae, praesentium unde cupiditate corrupti eveniet. Voluptatum optio tenetur quae quibusdam eveniet suscipit totam blanditiis!",
    anchor:"#zoo",
    images:[
  { src: "gallery-zoo-13334676515_83e0c03425_w.jpg", alt: "Zoo" },
  { src: "gallery-zoo-13334860473_bb7c2d96b7.jpg", alt: "Zoo" },
  { src: "gallery-zoo-13335059814_8eaaf90590_w.jpg", alt: "Zoo" },
  { src: "gallery-zoo-13335079294_ccbc798ed4_w.jpg", alt: "Zoo" },
  { src: "gallery-zoo-13335087954_7d2f926c8b_w.jpg", alt: "Zoo" },
  { src: "gallery-zoo-13335097654_fddd4cc6b0_w.jpg", alt: "Zoo" },
  { src: "gallery-zoo-topnotch2.jpg", alt: "Zoo" },
  { src: "gallery-zoo-zoo.jpg", alt: "Zoo" },
  { src: "gallery-zoo-zoo2.jpg", alt: "Zoo" },
  { src: "gallery-zoo-zoo3.jpg", alt: "Zoo" },
  { src: "gallery-zoo-zoo4.jpg", alt: "Zoo" },
]}

export const interiorExteriorGallery = {
title: "Interior and Exterior Painting",
  text:
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic dolore consequatur, mollitia soluta voluptas, accusamus repudiandae, praesentium unde cupiditate corrupti eveniet. Voluptatum optio tenetur quae quibusdam eveniet suscipit totam blanditiis!",
    anchor:"#residential",
    images:[
  {
    src: "gallery-interior-exterior-1aftrhouse.jpg",
    alt: "Interior Exterior Painting",
  },
  {
    src: "gallery-interior-exterior-1b4house.jpg",
    alt: "Interior Exterior Painting",
  },
  {
    src: "gallery-interior-exterior-9aftr.jpg",
    alt: "Interior Exterior Painting",
  },
  {
    src: "gallery-interior-exterior-9b4.jpg",
    alt: "Interior Exterior Painting",
  },
  {
    src: "gallery-interior-exterior-aftrhouse.jpg",
    alt: "Interior Exterior Painting",
  },
  {
    src: "gallery-interior-exterior-b4house.jpg",
    alt: "Interior Exterior Painting",
  },
]}

export const woodGallery = {
  title: "Wood Refinishing and Restoration",
  text:
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic dolore consequatur, mollitia soluta voluptas, accusamus repudiandae, praesentium unde cupiditate corrupti eveniet. Voluptatum optio tenetur quae quibusdam eveniet suscipit totam blanditiis!",
    anchor:"#wood",
  images: [
    {
      src: "gallery-wood-13391928443_7809c9e311_z.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-13392162724_ff1353e5ab_z.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-1aftr.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-1b4.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-1woodaftr.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-1woodb4.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-2aftr.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-2b4.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-2woodaftr.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-2woodb4.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-3aftr.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-3b4.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-3woodaftr.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-3woodb4.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-4aftr.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-4b4.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-5aftr.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-5b4.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-6aftr.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-6b4.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-7aftr.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-7b4.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-8aftr.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-8b4.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-after.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-after1.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-b4.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-b4aftr1.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-bamboo.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-deck.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-deck1.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-deckaftr.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-deckb4.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-dog.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-house1.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-house2.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-house3.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-topnotch.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-wood.jpg",
      alt: "Wood Refinishing and Restoration",
    },
    {
      src: "gallery-wood-wood2.jpg",
      alt: "Wood Refinishing and Restoration",
    },
  ],
}

