import React from "react"
import tw, { styled } from "twin.macro"
import { H2, P, HeaderDivider } from "../page-elements"
import { getColor, Color } from "../../utils/getColor"

interface SectionSummaryProps {
  color?: Color
  text?: string
  title: string
}

const Container = tw.div`
  flex
  flex-col
  justify-between
  mx-auto
  w-full
  items-center

  mb-6
  md:mt-8
  md:mb-12
`

const Title = styled(H2)`
  ${tw`
        text-center
    `}
`

const SectionText = styled(P)`
  ${tw`
    w-full
    px-page-gutter
    md:px-0
    md:w-1/2
    mx-auto
    text-center
    max-w-screen-md
  `}
`

export const SectionSummary: React.FC<SectionSummaryProps> = ({
  text,
  title,
  color = "light",
}) => (
  <Container>
    <Title color={color}>{title}</Title>
    <HeaderDivider />
    {text ? <SectionText color={color}>{text}</SectionText> : null}
  </Container>
)
