import React from "react"
import tw, { styled } from "twin.macro"
import Image from "../image"

type Image = {
  src: string
  alt: string
}

type Column = 2 | 3 | 4

interface GalleryProps {
  images: Image[]
  columns: Column
}

const Row = tw.div`
  flex
  flex-wrap
  flex-col
  md:flex-row
`

const StyledImage = styled(Image)`
  max-height: 300px;
`

const Column = styled.div`
  ${tw`
    flex-1
    p-4
    w-full
  `}
  @media (min-width: 768px) {
    max-width: ${({ columns }: { columns: Column }) => {
      return (100 / columns).toFixed(6) + "%"
    }};

    min-width: ${({ columns }: { columns: Column }) => {
      return (100 / columns).toFixed(6) + "%"
    }};
  }
`

export const Gallery: React.FC<GalleryProps> = ({ images, columns }) => {
  return (
    <Row>
      {images.map(({ src, alt }) => {
        return (
          <Column key={src} columns={columns}>
            <StyledImage src={src} alt={alt} />
          </Column>
        )
      })}
    </Row>
  )
}
