import React from "react"
import loadable from "@loadable/component"
import tw, { styled } from "twin.macro"
import { H1 } from "../components/page-elements"
import { Banner } from "../components/shared/banner"
import { CalloutSection } from "../components/shared/callout-section"
import { SectionSummary } from "../components/shared/section-summary"
import { Gallery } from "../components/shared/gallery"
import Content from "../content/portfolio-page-content.json"
import SEO from "../components/seo"
import CalloutContent from "../content/callout-section-content.json"
import {
  aldoGallery,
  zooGallery,
  interiorExteriorGallery,
  uhaulGallery,
  woodGallery,
} from "../content/galleries"

const Section = loadable(() => import("../components/shared/section"))
const backgroundImage = "glacier-background.jpg"

const Row = tw.div`
  flex
  flex-col
  justify-between
  md:flex-row
  md:mx-auto
  px-page-gutter
  md:px-0
  w-full
  max-w-screen-xl
`

const InnerH1 = styled(H1)`
  ${tw`text-center text-base-color my-8 md:my-16 xl:my-20 mx-auto`}
`

const PortfolioPage = () => {
  return (
    <>
      <SEO title={Content.meta.title} description={Content.meta.description} />
      <Banner src={backgroundImage}>
        <Row>
          <InnerH1>{Content.header}</InnerH1>
        </Row>
      </Banner>

      {/* {Content.galleries.map(gallery => {
        return (
          <Section
            key={gallery.title}
            size="xl"
            background="base-secondary-color"
          >
            <div className="w-full">
              <SectionSummary
                color="darker"
                title={gallery.title}
                text={gallery.subTitle}
              />
              <Gallery images={gallery.images} columns={3} />
            </div>
          </Section>
        )
      })} */}
      <Section
        size="xl"
        background="base-secondary-color"
        id={interiorExteriorGallery.anchor}
      >
        <div className="w-full">
          <SectionSummary
            title={interiorExteriorGallery.title}
            color="darker"
          />
          <Gallery
            images={interiorExteriorGallery.images}
            columns={3}
          ></Gallery>
        </div>
      </Section>

      <Section
        size="xl"
        background="base-secondary-color"
        id={woodGallery.anchor}
      >
        <div className="w-full">
          <SectionSummary title={woodGallery.title} color="darker" />
          <Gallery images={woodGallery.images} columns={3}></Gallery>
        </div>
      </Section>

      <Section
        size="xl"
        background="base-secondary-color"
        id={zooGallery.anchor}
      >
        <div className="w-full">
          <SectionSummary title={zooGallery.title} color="darker" />
          <Gallery images={zooGallery.images} columns={3}></Gallery>
        </div>
      </Section>

      <Section
        size="xl"
        background="base-secondary-color"
        id={uhaulGallery.anchor}
      >
        <div className="w-full">
          <SectionSummary title={uhaulGallery.title} color="darker" />
          <Gallery images={uhaulGallery.images} columns={3}></Gallery>
        </div>
      </Section>

      <Section
        size="xl"
        background="base-secondary-color"
        id={aldoGallery.anchor}
      >
        <div className="w-full">
          <SectionSummary title={aldoGallery.title} color="darker" />
          <Gallery images={aldoGallery.images} columns={3}></Gallery>
        </div>
      </Section>

      <CalloutSection
        title={CalloutContent.title}
        text={CalloutContent.text}
        buttonText={CalloutContent.buttonText}
        buttonUrl={CalloutContent.buttonUrl}
      />
    </>
  )
}

export default PortfolioPage
